:root {
    --transition-time: 0.25s;
}

html,
body {
    overflow: hidden;
    height: 100%;
    margin: 0;
    font-family: "Helvetica Neue", "Segoe UI", "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

input {
    box-sizing: border-box;
}

a {
    font-style: oblique;
    font-size: 0.95rem;
    color: #0ea32e;
}

.addContainer {
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 20px;
}

.formBackground {
    width: 40vw;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.9rem;
    padding: 2rem;
    background-color: rgba(128, 128, 128, 0.151);
    border-radius: 8px;
}

.textBox {
    width: 100%;
    font-size: 0.9rem;
    padding: 10px 8px;
    background-color: rgba(128, 128, 128, 0.208);
    border: none;
    border-radius: 5px;
    transition: ease var(--transition-time);
}
.textBox:hover {
    background-color: rgba(128, 128, 128, 0.28);
}

.submitButton {
    width: 100%;
    height: 30px;
    background-color: #17ba3a;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 30px;
    transition: ease var(--transition-time);
    cursor: pointer;
}
.submitButton:hover {
    background-color: #0ea32e;
}
