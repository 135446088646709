#header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: rgba(128, 128, 128, 0.151);
    padding-bottom: 0.2em;
    padding-top: 0.2em;
}

#content {
    display: flex;
    align-items: center;
    justify-content: center;
}

#logo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    /* height: "70px", width: "70px", padding: "0px", paddingLeft: "30px", margin: "0" */
}
